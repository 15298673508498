/**
 * Core global JS.
 */

// Import SCSS
import 'scss/index.scss';
import 'scss/site/index.scss';

import { ComponentManager } from 'js-component-framework';
import reactComponentManager from 'utils/reactComponentManager';
import headerConfig from 'components/header';
import headerSearchConfig from 'components/header-search';
import domContentLoaded from 'utils/domContentLoaded';
import wrapperConfig from 'components/wrapper';
import formsContainerConfig from 'components/forms';
import NBCWeather from 'components/header/weather';

// Create instance of the component manager using `nbc-global-components`
const manager = new ComponentManager('nbc-global-components');

domContentLoaded(() => {
  manager.initComponents([
    headerConfig,
    headerSearchConfig,
    wrapperConfig,
    formsContainerConfig,
  ]); // You can supply an array of configurations to start many components at once

  reactComponentManager({
    NBCWeather,
  });
});

if (module.hot) {
  module.hot.accept();
  // This is required for HMR, otherwise you'll need to refresh
  // the page to see JS changes
  manager.initComponents([
    headerConfig,
    headerSearchConfig,
    wrapperConfig,
    formsContainerConfig,
  ]);

  reactComponentManager({
    NBCWeather,
  });
}
