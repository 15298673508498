import Header from './header';

const headerConfig = {
  name: 'siteHeader',
  class: Header,
  querySelector: {
    headerSecondary: '.site-header__secondary',
    trendingToggle: '.trending-toggle',
    trendingToggleWrap: 'site-header__trending-toggle',
    trendingNav: '.site-header__trending-nav',
    trendingWrap: '.site-header__trending',
    sitePrimary: '.site-header__primary',
    animatedSection: '.site-header__animated-section',
    searchToggle: '.site-header__search-toggle',
  },
  querySelectorAll: {
    trendingItems: '.site-header__trending-nav a',
  },
  options: {},
};

export default headerConfig;
