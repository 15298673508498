import lscache from 'lscache';

const {
  nbc: {
    brand = '',
    callLetters = '',
    env = '',
    identity = {},
    dataLayer: {
      domainName = '',
      division = '',
    },
    fullDomain = '',
  },
  wp: {
    apiFetch,
  } = {},
} = window;

const dashboardUrl = 'telemundo' === brand ? 'cuenta' : 'account';

/**
 * Return logo path segment by division
 * @param {string} property tlmd, nbc, or rsn
 *
 * @returns {string} division path
 */
const getLogoPathDivision = (property) => {
  switch (property) {
    case 'rsn':
      return 'nbc_sports';
    case 'telemundo':
      return 'telemundo';
    default:
      return 'nbc';
  }
};

/**
 * Return logo path segment by callletters
 * @param {string} callsign tlmd, nbc, or rsn
 *
 * @returns {string} callletters
 */
const getLogoPathCallsign = (callsign) => {
  switch (callsign.toUpperCase()) {
    case 'NSBO':
      return 'NBCSB';
    case 'NSBA':
      return 'NBCSBA';
    case 'NSPH':
      return 'NBCSP';
    case 'NSCH':
      return 'NBCSC';
    default:
      return callsign;
  }
};

/**
 * Return logo path segment by device type
 *
 * @returns {string} device type: mobile, desktop, tablet
 */
const detectDeviceType = () => {
  if ('maxTouchPoints' in navigator && 0 < navigator.maxTouchPoints) {
    if (window.matchMedia('(max-width: 767px)').matches) {
      return 'mobile';
    } else {
      return 'tablet';
    }
  }

  return 'desktop';
};

/**
 * Initialize Unified
 */
export const identityInitialize = () => {
  const sdkDomain = ['preprod', 'production'].includes(env) ? 'id' : 'stage.id-envs';

  identity.initialize((() => {
    switch (division) {
      case 'rsn':
        return 'rsnsweb';
      case 'telemundo':
        return 'telemundolocalweb';
      default:
        return 'localstationsweb';
    }
  })(), {
    checkoutEnabled: false,
    configLocationHost: `https://${sdkDomain}.nbcuni.com/websdk/config`,
    debug: true,
    env: ['preprod', 'production'].includes(env) ? 'production' : 'stage',
    language: 'telemundo' === brand ? 'es' : 'en',
    native: false,
    useBaseSdkLocation: false,
    version: '2',
    crossDomain: true,
    identityConfigOverrides: {
      brand: {
        product: fullDomain,
      },
      crossDomain: {
        oAuthBrandLogo: `/websdk/assets/local/logos/${getLogoPathDivision(division)}/${getLogoPathCallsign(callLetters).toUpperCase()}_logo_${detectDeviceType()}.svg`,
        redirectUrl: `https://${domainName}/${dashboardUrl}/`,
        clientId: (() => {
          switch (division) {
            case 'rsn':
              return 'rsn_web';
            case 'telemundo':
              return 'local_telemundo_web';
            default:
              return 'local_nbc_web';
          }
        })(),
      },
    },
  });
};

/**
 * Launch identity login screen
 */
export const identityLogin = (noid = null) => {
  const brandData = (noid) ? {
    saveBookmark: noid,
  } : {};
  identity.launch({
    referringPage: 'nbc-web-sdk-sample',
    registrationReferrer: 'None',
    defaultPage: 'createProfile',
    redirectUrl: `https://${domainName}/${dashboardUrl}/`,
    brandData: btoa(JSON.stringify(brandData)),
  });
};

/**
 * Logout of identity sdk
 */
export const identityLogout = () => {
  identity.unauthenticate();
};

/**
 * Subscribe to Authentication Status
 */
export const identityAuthSubscribe = (func) => {
  identity.on('authenticationState', (status) => {
    func(status);
  });
};

/**
 * On Mobile screens, toggle open identity menu options
 */
export const identityNavOpenToggle = (element) => {
  if (window.matchMedia('(max-width: 768px)').matches) {
    const identityMenu = element.querySelector('.identity-profile__menu-container');
    const identityMenuClass = 'identity-profile__menu-container--show';
    if (identityMenu.classList.contains(identityMenuClass)) {
      identityMenu.classList.remove(identityMenuClass);
    } else {
      identityMenu.classList.add(identityMenuClass);
    }
  }
};

/**
 * Reattach event listeners on non Fast enabled domains, if nav elements are destroyed and recreated: themes/nbc-station/client/src/components/header/header.js
 */
export const identityNav = () => {
  const identityMenu = document.querySelector('.identity-profile');
  const identitySignin = identityMenu?.querySelector('.identity-profile__signin');
  const identitySignout = identityMenu?.querySelector('.identity-profile__signout');

  identityMenu?.addEventListener('click', () => {
    identityNavOpenToggle(identityMenu);
  });

  identitySignin?.addEventListener('click', (e) => {
    e.preventDefault();
    identityLogin();
    return false;
  });

  identitySignout?.addEventListener('click', (e) => {
    e.preventDefault();
    identityLogout();
    return false;
  });
};

/**
  * Save or unsave article/video.
  * @param {string} noid Network Object ID of content
  * @param {boolean} save Save or unsave
  * @param {Function} callBack
*/
export const identityBookmarkAction = async (noid, isSaved, callBack, updateNoids) => {
  const apiPath = isSaved ? 'unbookmark' : 'bookmark';

  const {
    token,
  } = identity;

  // eslint-disable-next-line no-underscore-dangle
  const customerId = identity.getUserDetails()._id;

  await apiFetch({
    path: `nbc/unified/${apiPath}`,
    method: 'post',
    headers: {
      'x-auth-token': token,
    },
    data: {
      customerId,
      noid,
    },
  }).then((res) => {
    const storedBookmarks = lscache.get(customerId);
    if (! storedBookmarks) {
      lscache.set(customerId, [noid]);
    }

    if (! isSaved) {
      if (! storedBookmarks.includes(noid)) {
        lscache.set(customerId, [noid, ...storedBookmarks]);
      }
    } else {
      lscache.set(customerId, [...storedBookmarks.filter((item) => item !== noid)]);
    }

    if (updateNoids) {
      updateNoids(lscache.get(customerId));
    }

    if (callBack) {
      callBack(res);
    }
  }).catch(() => {
    identity.validateSession()
      .then((isValid) => {
        if (! isValid) {
          identityLogin();
        }
      });
  });
};

/**
 * Check for brandData param, and if its an object with a saveBookmark noid, return it
 *
 * @returns {string} Noid
 */
export const decodeBrandData = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const brandData = params.get('brandData');
  let decodedBrandData = null;

  if (! brandData) {
    return decodedBrandData;
  }

  try {
    decodedBrandData = JSON.parse(window.atob(brandData));
  } catch (e) {
    return null;
  }

  if (decodedBrandData && 'object' === typeof decodedBrandData && 'saveBookmark' in decodedBrandData) {
    const {
      saveBookmark = '',
    } = decodedBrandData;

    return saveBookmark;
  } else {
    return null;
  }
};
