import { Component } from 'js-component-framework';

/**
 * Component for handling form helpers.
 */
export default class FormsContainer extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    function addFocusedClass(el) {
      el.classList.add('focused');
    }

    function removeFocusedClass(el) {
      el.classList.remove('focused');
    }

    function addRemoveNotEmptyClass(el, container) {
      if ('' === el.value) {
        container.classList.remove('notempty');
      } else {
        container.classList.add('notempty');
      }
    }

    function ninjaFormLabelAnimation() {
      const forms = document.querySelectorAll('.nf-form-cont.short-form');

      if (0 !== forms.length) {
        const formsArr = Array.from(forms);

        formsArr.forEach((form) => {
          const containers = form.querySelectorAll('.nf-field .field-wrap');
          const containerArr = Array.from(containers);

          containerArr.forEach((container) => {
            const input = container.querySelector('input');
            const textarea = container.querySelector('textarea');

            if (null !== input) {
              input.addEventListener('focus', () => {
                addFocusedClass(container);
              });

              input.addEventListener('blur', () => {
                removeFocusedClass(container);
                addRemoveNotEmptyClass(input, container);
              });
            }

            if (null !== textarea) {
              textarea.addEventListener('focus', () => {
                addFocusedClass(container);
              });

              textarea.addEventListener('blur', () => {
                removeFocusedClass(container);
                addRemoveNotEmptyClass(textarea, container);
              });
            }
          });
        });
      }
    }

    function customFormLabelAnimation() {
      const forms = document.querySelectorAll('form.short-form');

      if (0 !== forms.length) {
        const formsArr = Array.from(forms);

        formsArr.forEach((form) => {
          const containers = form.querySelectorAll('.field-wrap');
          const containerArr = Array.from(containers);

          containerArr.forEach((container) => {
            const label = container.querySelector('label');
            const input = container.querySelector('input');
            const textarea = container.querySelector('textarea');

            if (null !== label) {
              label.addEventListener('click', () => {
                if (! container.classList.contains('focused')) {
                  addFocusedClass(container);

                  if (null !== input) {
                    input.focus();
                  }
                  if (null !== textarea) {
                    textarea.focus();
                  }
                }
              });
            }

            if (null !== input) {
              input.addEventListener('focus', () => {
                addFocusedClass(container);
              });

              input.addEventListener('blur', () => {
                removeFocusedClass(container);
                addRemoveNotEmptyClass(input, container);
              });
            }

            if (null !== textarea) {
              textarea.addEventListener('focus', () => {
                addFocusedClass(container);
              });

              textarea.addEventListener('blur', () => {
                removeFocusedClass(container);
                addRemoveNotEmptyClass(textarea, container);
              });
            }
          });
        });
      }
    }

    // Call functions if the document is already loaded.
    if ('complete' === document.readyState || 'loaded' === document.readyState) { // eslint-disable-line max-len
      ninjaFormLabelAnimation();
      customFormLabelAnimation();
    } else {
      document.addEventListener('DOMContentLoaded', ninjaFormLabelAnimation, false); // eslint-disable-line max-len
      document.addEventListener('DOMContentLoaded', customFormLabelAnimation, false); // eslint-disable-line max-len
    }
  }
}
