import { Component } from 'js-component-framework';

/**
 * Component for Page Wrapper
 */
export default class Wrapper extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    /**
    * Set up variables
    */
    const {
      alertBars,
    } = this.children;

    /**
     * Check if we're on a template with an absolutely-positioned bg image.
     *
     * @return {bool}
     */
    const checkForArticleTemplate = () => {
      const body = document.querySelector('body');

      if (body.classList.contains('featured-article') || body.classList.contains('immersive-article')) { // eslint-disable-line max-len
        return true;
      }

      return false;
    };

    /**
     * Get the absolutely positioned background element.
     *
     * @return {mixed}
     */
    const getBackgroundContainerEl = () => {
      const featuredBackground = document.querySelector('.featured-article-background'); // eslint-disable-line max-len
      const immersiveBackground = document.querySelector('.immersive-article-background'); // eslint-disable-line max-len

      if (featuredBackground) {
        return featuredBackground;
      }

      if (immersiveBackground) {
        return immersiveBackground;
      }

      return null;
    };

    /**
     * Get combined height of all alert bars on the page.
     *
     * @return {int} height Height of all alert bars.
     */
    const getAlertBarsHeight = () => {
      const bars = Array.from(alertBars);
      const height = bars.reduce((acc, bar) => acc + bar.clientHeight, 0);

      return height;
    };

    /**
     * Handle the top positioning to show the alert bars.
     */
    function handleTopPos() {
      const backgroundContainer = getBackgroundContainerEl();
      const height = getAlertBarsHeight();
      const topPos = `${height}px`;

      if (backgroundContainer) {
        backgroundContainer.style.top = topPos;
      }
    }

    /**
     * Set the background element top position under alert bars.
     */
    function setBackgroundTopPos() {
      const specialTemplate = checkForArticleTemplate();

      if (alertBars.length && specialTemplate) {
        handleTopPos();
      }
    }

    window.addEventListener('load', setBackgroundTopPos);
    window.addEventListener('resize', setBackgroundTopPos);
  }
}
