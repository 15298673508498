import Wrapper from './wrapper';

const wrapperConfig = {
  name: 'wrapper',
  class: Wrapper,
  querySelectorAll: {
    alertBars: '.alert-bar',
  },
};

export default wrapperConfig;
