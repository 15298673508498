import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withWeatherData from '../weather/withWeatherData';

/**
 * Weather component.
 */
const NBCWeather = (props) => {
  const {
    data: {
      current,
      alerts,
    },
    view,
    alertSingular,
    alertPlural,
  } = props;

  const {
    weather: {
      alerts_url: alertsUrl,
      weather_url: pageUrl,
    },
  } = nbc;

  const alertString = 1 === alerts.length ?
    `${alerts.length} ${alertSingular}` : `${alerts.length} ${alertPlural}`;
  return (
    <Fragment>
      {('conditions' === view && current) && (
        <div className="site-header__weather">
          <a className="site-header__weather-link" href={pageUrl}>
            <div
              className={
                `site-header__weather-icon weather-icon--${current.iconCode}`
              }
            >
              <span className="site-header__weather-icon-text">
                {current.conditions || ''}
              </span>
            </div>
            <div className="site-header__weather-value">
              {current.temp ? `${current.temp}º` : ''}
            </div>
          </a>
        </div>
      )}
      {'alerts' === view && alerts && 0 < alerts.length && (
        <Fragment>
          <a
            href={alertsUrl}
            className="site-header__alerts-item
              site-header__alerts-item--weather"
          >
            {alertString}
          </a>
          <span className="item-divider" />
        </Fragment>
      )}
    </Fragment>
  );
};
NBCWeather.defaultProps = {
  alertSingular: '',
  alertPlural: '',
};

NBCWeather.propTypes = {
  view: PropTypes.string.isRequired,
  data: PropTypes.shape({
    current: PropTypes.shape({
      iconCode: PropTypes.string,
      conditions: PropTypes.string,
      temp: PropTypes.string,
    }),
    alerts: PropTypes.array,
  }).isRequired,
  alertSingular: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  alertPlural: PropTypes.string,
};

const wrapWithWeatherData = withWeatherData('header');
export default wrapWithWeatherData(NBCWeather);
