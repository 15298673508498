import HeaderSearch from './header-search';

const headerSearchConfig = {
  name: 'headerSearch',
  class: HeaderSearch,
  querySelector: {
    toggle: '.site-header__search-toggle',
    form: '.site-header__search-form',
    searchInput: '.site-header__search-form input[type="search"]',
  },
};

export default headerSearchConfig;
