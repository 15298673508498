import { Component } from 'js-component-framework';
import './header-search.scss';

/**
 * Component for Header Search
 */
export default class HeaderSearch extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    /**
    * Set up variables
    */
    const {
      toggle,
      form,
      searchInput,
    } = this.children;

    const searchContainer = document.querySelector('.site-header__search');

    /**
     * Open the header search form on button click.
     *
     */
    function openSearch(e) {
      e.preventDefault();
      searchContainer.classList.add('search-open');
      toggle.classList.add('form-open');
      form.classList.add('open');
      searchInput.focus();

      document.addEventListener('click', closeSearch);
    }

    /**
     * Remove the document event listener if the search form is closed.
     */
    function removeCloseSearch() {
      document.removeEventListener('click', closeSearch);
    }

    /**
     * Close the search form on any outside element click.
     *
     */
    function closeSearch(e) {
      if (toggle === e.target || null !== e.target.closest('.site-header__search-form')) { // eslint-disable-line max-len
        return;
      }

      if (null === e.target.closest(this.element)) {
        searchContainer.classList.remove('search-open');
        toggle.classList.remove('form-open');
        form.classList.remove('open');
        searchInput.value = '';
        removeCloseSearch();
      }
    }

    toggle.addEventListener('click', openSearch);
    document.addEventListener('click', closeSearch);
  }
}
