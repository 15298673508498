// File for logic corresponding to header component
import { Component } from 'js-component-framework';
import './header.scss';

import {
  identityNav,
} from '../unified-identity/utils';

/**
 * Component for site header
 */
export default class Header extends Component {
  /**
   * Start the component
   */
  constructor(config) {
    super(config);

    /**
    * Set up variables
    */
    const {
      headerSecondary,
      trendingToggle,
      trendingNav,
      trendingItems,
      trendingWrap,
      sitePrimary,
      animatedSection,
    } = this.children;

    const siteHeader = document.querySelector('.site-header');
    const trendingToggleWrap =
      trendingToggle ? trendingToggle.parentNode : null;
    const body = document.querySelector('body');
    const shareButtons = document.querySelector('.site-content .article-meta .share-buttons'); // eslint-disable-line max-len
    const newHeaderHeadline = createHeaderHeadline();
    const transitionDuration = 300; // Matches css transition duration for fade-out;
    const fastButton = document.querySelector('.site-header__fast'); // FAST global nav button.

    /**
    * Detect if trending navigation has overflown
    */
    function detectTrendingOverflow() {
      if (! trendingNav) {
        return;
      }

      if ((trendingNav.scrollWidth <= trendingNav.offsetWidth) &&
        ! trendingNav.classList.contains('open')) {
        // Hide arrow toggle while all showing
        trendingToggle.parentElement.classList.add('hide');

        // Add items back into tab flow while all showing
        trendingItems.forEach((trendingItem) => {
          trendingItem.removeAttribute('tabindex');
        });
      } else {
        // Show arrow toggle while all showing
        trendingToggle.parentElement.classList.remove('hide');

        // Remove items from tab flow while truncated
        trendingItems.forEach((trendingItem) => {
          // If item is not showing, remove from tab flow
          if (trendingItem.offsetLeft >= trendingNav.offsetWidth) {
            trendingItem.setAttribute('tabindex', '-1');
          }
        });
      }
    }
    window.addEventListener('load', detectTrendingOverflow);
    window.addEventListener('resize', detectTrendingOverflow);

    /**
    * Toggle the trending menu on click
    */
    function toggleTrendingMenu(e) {
      if (! trendingNav) {
        return;
      }

      e.preventDefault();

      headerSecondary.classList.toggle('open');
      trendingToggle.classList.toggle('open');
      trendingNav.classList.toggle('open');
      trendingWrap.classList.toggle('open');

      trendingItems.forEach((trendingItem) => {
        // Remove all tabindex attributes
        trendingItem.removeAttribute('tabindex');

        // Adds tabindex attribute if overflow is present
        if (trendingItem.offsetLeft >= trendingNav.offsetWidth) {
          trendingItem.setAttribute('tabindex', '-1');
        }
      });

      // Move toggle for a more natural tab flow
      if (trendingNav.classList.contains('open')) {
        // Moves before nav menu when menu is open
        trendingWrap.insertBefore(trendingToggleWrap, trendingNav);
      } else {
        // Moves after nav menu when menu is closed
        trendingWrap.insertBefore(trendingToggleWrap, trendingNav.nextSibling);
      }
    }

    if (trendingToggle) {
      trendingToggle.addEventListener('click', toggleTrendingMenu);
    }

    /**
     * Create headline and share buttons for header
     */
    function createHeaderHeadline() {
      if (! body.classList.contains('single')) {
        return null;
      }

      const articleHeadline = document.querySelector('.article-headline');
      if (! articleHeadline) {
        return null;
      }

      const sponsored = document.querySelector('.sponsored-notice__text');
      const headerHeadlineEl = document.createElement('h3');
      const headlineOnly = articleHeadline.innerText;
      const headlineText = document.createTextNode(headlineOnly);
      const headlineSpan = document.createElement('span');
      const headerShareButtons = document.createElement('div');
      const headerArticleMeta = document.createElement('div');

      if (null !== sponsored) {
        const sponsoredSpan = document.createElement('span');
        sponsoredSpan.setAttribute('class', 'sponsored');
        const sponsoredText = document.createTextNode(sponsored.dataset.header_text); // eslint-disable-line max-len
        sponsoredSpan.appendChild(sponsoredText);
        headerHeadlineEl.appendChild(sponsoredSpan);
      }

      headlineSpan.classList.add('site-header__headline-text');
      headlineSpan.appendChild(headlineText);
      headerHeadlineEl.appendChild(headlineSpan);
      headerHeadlineEl.classList.add('site-header__article-headline');

      if (shareButtons) {
        const buttonsCopy = shareButtons.cloneNode(true);
        headerShareButtons.appendChild(buttonsCopy);
        headerShareButtons.classList.add('site-header__sharing');
      }

      headerArticleMeta.classList.add('site-header__article-meta');
      headerArticleMeta.appendChild(headerHeadlineEl);
      headerArticleMeta.appendChild(headerShareButtons);

      return headerArticleMeta;
    }

    /**
     * Animate in the article meta to header
     */
    function addArticleMetaToHeader() {
      if (! animatedSection.classList.contains('meta-appended')) {
        animatedSection.classList.add('fade-out');
        setTimeout(() => {
          if (! fastButton) {
            animatedSection.innerHTML = '';
            animatedSection.appendChild(newHeaderHeadline);
          }

          animatedSection.classList.add('meta-appended');
          siteHeader.classList.add('meta-appended');
          animatedSection.classList.remove('fade-out');
        }, transitionDuration);
      }
    }

    /**
     * Animate out the article meta from header
     *
     * @param storedHeaderEls string The header elements present on page load.
     */
    function removeArticleMetaFromHeader(storedHeaderEls) {
      if (animatedSection.classList.contains('meta-appended')) {
        animatedSection.classList.add('fade-out');
        setTimeout(() => {
          if (! fastButton) {
            animatedSection.innerHTML = '';
            animatedSection.innerHTML = storedHeaderEls;
            identityNav();
          }
          animatedSection.classList.remove('fade-out');
        }, transitionDuration);
      }
      animatedSection.classList.remove('meta-appended');
      siteHeader.classList.remove('meta-appended');
      if (sitePrimary.classList.contains('share-open')) {
        sitePrimary.classList.remove('share-open');
      }
    }

    /**
     * Toggle headline and share buttons (article meta) in header based on scroll position
     *
     * @param storedHeaderEls string The header elements present on page load.
     */
    function toggleArticleMetaInHeaderDesktop(storedHeaderEls) {
      const articleHeadline = document.querySelector('.article-headline');
      const sponsored = document.querySelector('.sponsored-notice__text');
      const leaderboard = document.getElementById('leaderboard');

      const heightToScroll = sponsored ?
        (sponsored.offsetTop + sponsored.offsetHeight + leaderboard.offsetHeight) : // eslint-disable-line max-len
        (articleHeadline.offsetTop + articleHeadline.offsetHeight + leaderboard.offsetHeight) - siteHeader.offsetHeight; // eslint-disable-line max-len
      if (window.scrollY > heightToScroll) {
        addArticleMetaToHeader();
      } else {
        removeArticleMetaFromHeader(storedHeaderEls);
      }
    }

    /**
     * Handle the header elements on single articles
     */
    function handleHeaderElements() {
      if (
        body.classList.contains('single') &&
        ! body.classList.contains('single-video')) {
        const storedHeaderEls = animatedSection.innerHTML;
        window.addEventListener('scroll', () => {
          toggleArticleMetaInHeaderDesktop(storedHeaderEls);
        });
      }
    }
    window.addEventListener('load', handleHeaderElements);
    window.addEventListener('resize', handleHeaderElements);

    const sponsoredButton = document.querySelector('.sponsored-notice__button');
    if (sponsoredButton) {
      sponsoredButton.addEventListener('click', toggleFocus);
    }

    /**
     * Toggle the focus on click for mobile
     */
    function toggleFocus(e) {
      e.preventDefault();
      e.target.focus();
      sponsoredButton.classList.toggle('open');
    }

    function checkHeaderScroll() {
      // Detect scroll position
      const scrollPosition = Math.round(window.scrollY);

      // If we've scrolled 2px past the leaderboard or the top, add "sticky" class to the header
      const leaderboard = document.getElementById('leaderboard');
      if (2 + leaderboard.offsetHeight < scrollPosition) {
        sitePrimary.classList.add('sticky');
      } else {
        sitePrimary.classList.remove('sticky');
        leaderboard.classList.remove('ad-sticky');
      }
    }
    window.addEventListener('scroll', checkHeaderScroll);

    let canStickAd = true;

    function stickAd() {
      const scrollPosition = Math.round(window.scrollY);
      const leaderboard = document.getElementById('leaderboard');

      if (30 + leaderboard.offsetHeight < scrollPosition) {
        if (canStickAd) {
          leaderboard.classList.add('ad-sticky');
          setTimeout(() => {
            leaderboard.classList.remove('ad-sticky');
          }, 5000);
          canStickAd = false;
        }
      }
    }
    window.addEventListener('scroll', stickAd);
  } // end constructor
}
